import { createApp } from 'vue'
import App from './App.vue'
import '../src/assets/scss/tailwind.scss';
import '../src/assets/css/materialdesignicons.min.css';
import '../src/libs/@iconscout/unicons/css/line.css';
import './assets/css/tailwind.css';
import './libs/tobii/css/tobii.min.css'
import '../src/assets/fonts/font-awesome.css'
import router from './router';
// import VueGtag from "vue-gtag";
const app = createApp(App)

app.use(router)
// app.use(VueGtag, {
//     config: { id: "G-YJVZYVMXEP" }
//   })
app.mount('#app')
