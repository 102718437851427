<template>
  <div>
    <!-- Start -->
    <section class="relative md:py-24 py-16" id="blog">
      <div class="container">
        <div class="grid grid-cols-1 pb-8 text-center">
          <h3 class="mb-4 md:text-2xl text-xl font-medium">Blogs</h3>

          <p class="text-slate-400 dark:text-slate-300 max-w-xl mx-auto">
            What's New about AstroWarp
          </p>
        </div>
        <!--end grid-->

        <div
          class="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-[30px] mt-8"
        >
          <div
            v-for="item in blogData"
            :key="item"
            class="blog relative rounded-md shadow shadow-slate-200 dark:shadow-gray-800 overflow-hidden"
          >
            <img :src="item.image" alt="" />

            <div class="content p-6">
              <router-link
                :to="{
                  name: 'Blog-Detail',
                  params: { blogIndex: item.blogIndex },
                }"
                class="text-lg hover:text-blue-600 dark:text-white dark:hover:text-blue-600 transition duration-500 ease-in-out font-medium h5"
                >{{ item.title }}</router-link
              >
              <p class="text-slate-400 mt-3">{{ item.desc }}</p>

              <div class="mt-5">
                <router-link
                  :to="{
                    name: 'Blog-Detail',
                    params: { blogIndex: item.blogIndex },
                  }"
                  class="hover:text-blue-600 dark:hover:text-blue-600 after:bg-blue-600 dark:text-white transition duration-500"
                  >Read More <i class="uil uil-arrow-right"></i
                ></router-link>
              </div>
            </div>
          </div>
        </div>
        <!--end grid-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- End -->
  </div>
</template>

<script>
import { blogData } from "@/assets/js/blogData.js";

export default {
  name: "BlogsVue",
  data() {
    return {
      blogData,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>