<template>
    <div>
  <!-- Start 
  <section class="relative md:py-24 py-16 md:pt-0 pt-0 bg-gray-50 dark:bg-slate-800" id="features">
            <div class="container">
                <div class="grid grid-cols-1 justify-center">
                    <div class="relative z-1">
                        <div class="grid lg:grid-cols-12 grid-cols-1 md:text-start text-center justify-center">
                            <div class="lg:col-start-2 lg:col-span-10">
                                <div class="relative">
                                    <img src="../assets//images/bg/scenario_bandwidth_bonding.jpg" class="rounded-md shadow-lg" alt="">
                                    <div class="absolute bottom-2/4 translate-y-2/4 end-0 start-0 text-center">
                                        <a href="#!" @click="toggle" data-type="youtube" data-id="yba7hPeTSjk" class="lightbox lg:size-20 size-16 rounded-full shadow-lg dark:shadow-gray-800 inline-flex items-center justify-center bg-white hover:bg-violet-600 text-violet-600 hover:text-white duration-500 ease-in-out mx-auto">
                                            <i class="mdi mdi-play inline-flex items-center justify-center text-3xl"></i>
                                        </a> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="content md:mt-8">
                            <div class="grid lg:grid-cols-12 grid-cols-1 md:text-start text-center justify-center">
                                <div class="lg:col-start-2 lg:col-span-10">
                                    <div class="grid md:grid-cols-2 grid-cols-1 items-center gap-[30px]">
                                        <div class="mt-8">
                                            <div class="section-title text-md-start">
                                                <h6 class="text-white">The Simplest </h6>
                                                <h3 class="md:text-2l text-xl font-medium text-white mt-2">Customizable Network  <br> to Set Up.</h3>
                                            </div>
                                        </div>

                                        <div class="section-title text-md-start">
                                            <p class="text-white/50 max-w-xl mx-auto mb-2">Setting up your network has never been easier. AstroWarp's user-friendly visual configuration guide simplifies the process with just a few steps. </p>
                                            <a href="/services" class="text-white">Read More <i class="uil uil-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            <div class="absolute bottom-0 end-0 start-0 h-4/5 md:h-2/3 bg-blue-600"></div>
        </section>-->
        <!-- End -->
        <div :class="isActive ? 'fixed' : 'hidden' " class="bg-black/[0.9] top-0 left-0 bottom-0 w-[100%] h-[100%] z-999">
                <div class="h-[100%] flex items-center justify-center">
                    <div class="relative inline-block">
                        <iframe src="https://www.youtube.com/embed/wOm1PS4Z-fY" width="700" height="394" frameborder="0"></iframe>
                        <a class="mdi mdi-close text-white absolute top-1 right-1 text-xl" @click="toggle"></a>
                    </div>
                </div>
          </div>
    </div>
</template>

<script>
    export default {
        name: "ctaVue",
        data() {
            return {
                isActive: false,
            }
        },
        methods: {
            toggle(){
                if(!this.isActive){
                    this.isActive = true;
                }else{
                    this.isActive = false;
                }
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>