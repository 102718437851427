<template>
 <!-- <HelloWorld /> -->
  <router-view :key="$route.fullPath"></router-view>
  <!-- <Footer /> -->
  <Switcher />
</template>

<script>
  import feather from 'feather-icons'
// import Footer from './components/Footer.vue';
import Switcher from './components/Switcher.vue'

// import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    // Footer,
    Switcher
},
  mounted() {
      feather.replace();
  },
}
</script>

<style>

html,body,#app{
    height: 100%;
    width: 100%;
    /* overflow-x: hidden; */
}

</style>
