<template>
  <div>
    <!-- Start -->
    <section class="relative md:py-24 py-16" id="home">
      <div class="container mx-auto">
        <div
          class="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 gap-10 items-center"
        >
          <div class="lg:col-span-5">
            <img
              src="../assets/images/about.jpg"
              class="rounded-lg shadow-lg relative"
              alt=""
            />
          </div>
          <!--end col-->

          <div class="lg:col-span-7">
            <div class="lg:ms-7">
              <h3 class="mb-4 md:text-2xl text-xl font-medium">
                About AstroWarp
              </h3>

              <p class="text-slate-400 dark:text-slate-300 max-w-2xl mx-auto">
                AstroWarp is an advanced SD-WAN platform designed to provide
                seamless remote networking, traffic aggregation, and remote
                device management. Built specifically for router integration,
                AstroWarp supports comprehensive device management across entire
                networks, enabling both upper and lower device control. It
                leverages multi-link aggregation transmission based on MPTCP to
                ensure reliable, high-performance connections. With a focus on
                network-wide management and future support for hardware-level
                control, AstroWarp offers a more robust and dependable solution
                for managing devices and maintaining secure, stable networks.
              </p>

              <!-- <div class="relative mt-8">
                                <router-link to="/aboutus"
                                    class="py-2 px-5 inline-block font-normal tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-blue-600 hover:bg-blue-900 border-blue-600 hover:border-blue-700 text-white rounded-md">Read
                                    More <i class="uil uil-arrow-right"></i></router-link>
                            </div> -->
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end grid-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- End -->
  </div>
</template>

<script>
import feather from "feather-icons";

export default {
  name: "AboutUs",
  data() {
    return {};
  },
  mounted() {
    feather.replace();
  },
};
</script>

<style lang="scss" scoped></style>