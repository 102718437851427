<template>
  <div>
    <!-- Start -->
    <section
      class="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800"
      id="contact"
    >
      <div class="container">
        <div class="grid grid-cols-1 pb-8 text-center">
          <h3 class="mb-4 md:text-2xl text-xl font-medium">Have a question? Get in touch!</h3>

          <p class="text-slate-400 dark:text-slate-300 max-w-xl mx-auto">
            We look forward to hearing from you!
          </p>
        </div>
        <!--end grid-->

        <div
          class="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 mt-8 items-center gap-[30px]"
        >
          <div class="lg:col-span-8">
            <div class="p-6 rounded-md shadow bg-white dark:bg-slate-900">
              <form
                action="https://gl-inet.us16.list-manage.com/subscribe/post?u=8ed66b6ad326f7c2f2f6127f4&amp;id=24702471e1&amp;f_id=001730e0f0"
                method="post"
                name="mc-embedded-subscribe-form"
              >
                <div class="grid lg:grid-cols-12 lg:gap-[30px]">
                  <div class="lg:col-span-6 mb-5">
                    <input
                      name="NAME"
                      v-model="name"
                      type="text"
                      class="form-input w-full py-2 px-3 h-10 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0"
                      placeholder="Name :"
                    />
                  </div>

                  <div class="lg:col-span-6 mb-5">
                    <input
                      name="EMAIL"
                      type="email"
                      v-model="email"
                      class="form-input w-full py-2 px-3 h-10 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0"
                      placeholder="Email :"
                    />
                  </div>
                  <!--end col-->
                </div>

                <div class="grid grid-cols-1">
                  <div class="mb-5">
                    <input
                      name="SUBJECT"
                      v-model="subject"
                      class="form-input w-full py-2 px-3 h-10 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0"
                      placeholder="Subject :"
                    />
                  </div>

                  <div class="mb-5">
                    <textarea
                      name="MESSAGE"
                      v-model="comments"
                      class="form-input w-full py-2 px-3 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0 h-28"
                      placeholder="Message :"
                    ></textarea>
                  </div>
                </div>
                <div class="grid grid-cols-1">
                  <div class="mb-5">
                    <label>Marketing Permissions</label>
                  </div>
                  <p class="mb-5">
                    Please select all the information you would like to hear
                    from AstroWarp:
                  </p>
                  <div class="mb-5">
                    <fieldset
                      class="mc_fieldset gdprRequired mc-field-group"
                      name="interestgroup_field"
                    >
                      <label class="checkbox subfield block" for="gdpr216882"
                        ><input
                          type="checkbox"
                          id="gdpr_216882"
                          name="gdpr[216882]"
                          class="mr-2"
                          value="Y"
                        /><span>Product News</span></label
                      ><label class="checkbox subfield block" for="gdpr216885"
                        ><input
                          type="checkbox"
                          id="gdpr_216885"
                          name="gdpr[216885]"
                          class="mr-2"
                          value="Y"
                        /><span>Blog &amp; Tutorials</span></label
                      ><label class="checkbox subfield block" for="gdpr216886"
                        ><input
                          type="checkbox"
                          id="gdpr_216886"
                          name="gdpr[216886]"
                          class="mr-2"
                          value="Y"
                        /><span>Discount and Special Offers</span></label
                      >
                    </fieldset>
                  </div>
                  <p class="mb-5">
                    You can unsubscribe at any time by clicking the link in the
                    footer of our emails. For information about our privacy
                    practices, please visit our website.
                  </p>
    
                </div>
                <button
                  type="submit"
                  name="subscribe"
                  id="submit"
                  class="py-2 px-5 inline-block font-normal tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-md"
                >
                  Send Message
                </button>
                <!-- <a href="mailto:hi@astrowarp.net?name=${name}&emial=${email}&subject=主题&body=内容">1111</a> -->
              </form>
            </div>
          </div>

          <div class="lg:col-span-4">
            <div class="lg:ms-8">

              <!--
              <div class="flex">
                <div class="icons text-center mx-auto">
                  <i
                    class="uil uil-phone block rounded text-2xl dark:text-white mb-0"
                  ></i>
                </div>

                
                <div class="flex-1 ms-6">
                  <h5 class="text-lg dark:text-white mb-2 font-medium">
                    Phone
                  </h5>
                  <a href="tel:+152534-468-854" class="text-slate-400"
                    >+1 (571) 281 2688</a
                  >
                </div>
              </div>
              -->

              <div class="flex mt-4">
                <div class="icons text-center mx-auto">
                  <i
                    class="uil uil-envelope block rounded text-2xl dark:text-white mb-0"
                  ></i>
                </div>

                <div class="flex-1 ms-6">
                  <h5 class="text-lg dark:text-white mb-2 font-medium">
                    Email
                  </h5>
                  <a href="mailto:support@astrowarp.net" class="text-slate-400"
                    >support@astrowarp.net</a
                  >
                </div>
              </div>

              <div class="flex mt-4">
                <div class="icons text-center mx-auto">
                  <i
                    class="uil uil-map-marker block rounded text-2xl dark:text-white mb-0"
                  ></i>
                </div>

                <div class="flex-1 ms-6">
                  <h5 class="text-lg dark:text-white mb-2 font-medium">
                    Location
                  </h5>
                  <p class="text-slate-400 mb-2">
                    GL Intelligence, Inc.<br />
                    Suite 215, 10400 Eaton Place, <br />
                    Fairfax, VA 22030
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end grid-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- End -->
  </div>
</template>

<script>
export default {
  name: "GetInTouch",
  data() {
    return {
      comments: "",
      email: "",
      name: "",
      subject: "",
    };
  },
  methods: {
    submitForm() {
      // this.comments="";
      // this.email="";
      // this.name="";
      // this.subject="";
      // console.log(this.name, this.email, this.subject, this.comments);
      // var a = document.createElement("a");
      // a.href = `mailto:hi@astrowarp.net?name=${this.name}&emial=${this.email}&subject=主题&body=内容`;
      // a.style.display = "none"; // 隐藏a标签
      // // 添加到文档中
      // document.body.appendChild(a);
      // a.click();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>