import {createRouter,createWebHistory } from "vue-router"; //createWebHashHistory,createWebHistory
import index from "@/pages/Index.vue";
// import About from "@/views/About.vue";

const routes = [
  {
    path: "/",
    name: "Index",
    component: index,
  },
  {
    path: "/index-two",
    name: "IndexTwo",
    component: () => import('../pages/index-two.vue'),
  },
  {
    path: "/index-three",
    name: "IndexThree",
    component: () => import('../pages/index-three.vue'),
  },
  {
    path: "/index-four",
    name: "IndexFour",
    component: () => import('../pages/index-four.vue'),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import('../components/login.vue'),
  },
  {
    path: "/signup",
    name: "Signup",
    component: () => import('../components/Signup.vue'),
  },
  {
    path: "/reset-pass",
    name: "ResetPass",
    component: () => import('../components/reset-password.vue'),
  },
  {
    path: "/aboutus",
    name: "AboutUs",
    component: () => import('../pages/AboutUs.vue'),
  },
  {
    path: "/services/:id?",
    name: "Services",
    component: () => import('../pages/Services.vue'),
  },
  {
    path: "/blogs",
    name: "Blogs",
    component: () => import('../pages/Blogs.vue'),
  },
  {
    path: "/usercase",
    name: "UserCase",
    component: () => import('../pages/UserCase.vue'),
  },
  {
    path: "/download",
    name: "Download",
    component: () => import('../pages/Download.vue'),
  },
  {
    path: "/team",
    name: "Team",
    component: () => import('../pages/Team-Detail.vue'),
  },
  {
    path: "/pricing",
    name: "Pricing",
    component: () => import('../pages/Pricing.vue'),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import('../pages/ContactUs.vue'),
  },
  {
    path: "/blog-detail/:blogIndex",
    name: "Blog-Detail",
    component: () => import('../pages/BlogDetail.vue'),
  },
  {
    path: "/blog-detail-1",
    name: "Blog-Detail-1",
    component: () => import('../pages/BlogDetail.vue'),
  },

  {
    path: "/blog-detail-2",
    name: "Blog-Detail-2",
    component: () => import('../pages/BlogDetail.vue'),
  },

  {
    path: "/blog-detail-3",
    name: "Blog-Detail-3",
    component: () => import('../pages/BlogDetail.vue'),
  },
  {
    path: "/privacy-policy",
    name: "Terms-Condition",
    component: () => import('../pages/TermsCondition.vue'),
  },
  {
    path: "/service-agreement",
    name: "User-Service-Agreement",
    component: () => import('../pages/UserServiceAgreement.vue'),
  },



 
];


const router = createRouter({
  history: createWebHistory(), //createWebHashHistory(),createWebHistory
  routes,
});

router.beforeEach(
  (to,from,next)=>{
    console.log('to',to);
    if(to.fullPath.indexOf('/#/') === 0){
      next({path:to.fullPath.substring(2)})
    }else{
      next()
    }
    
  }
)


export default router;