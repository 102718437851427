<template>
    <div>
   <!-- Start -->
   <section class="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800" id="features">
            <div class="container lg mx-auto">
                <div class="grid grid-cols-1 pb-8 text-center">
                    <h3 class="mb-4 md:text-2xl text-xl font-medium">Why Choose AstroWarp</h3>
                    <!-- for Individuals and Small Enterprises <br/> -->
                    <p class="text-slate-400 max-w-xl mx-auto">Next-Level SD-WAN with Awesome Features</p>
                </div><!--end grid-->

                <div class="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-4 gap-[30px]">
                    <!-- Content -->
                    <div v-for="item in servicesData" :key="item" class="group relative lg:px-6 mt-4 transition duration-500 ease-in-out rounded-xl overflow-hidden text-center">
                        <div class="relative overflow-hidden text-transparent -m-3">
                            <i data-feather="hexagon" class="size-28 fill-green-100 mx-auto rotate-[30deg]"></i>
                            <div class="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-blue-600 rounded-xl transition duration-500 ease-in-out text-3xl flex align-middle justify-center items-center">
                                <i :class=item.icon></i>

                            </div>
                        </div>
                       
                        <div class="mt-6">
                            <router-link to="/services"  class="text-lg h5 transition duration-500 ease-in-out hover:text-violet-600">{{item.title}}</router-link>
                            <!-- <span   class="text-lg h5 transition duration-500 ease-in-out hover:text-violet-600">{{item.title}}</span> -->
                            <p class="text-slate-400 transition duration-500 ease-in-out mt-3">{{item.desc}}</p>
                        </div>
                    </div>
                    <!-- Content -->
                
                </div><!--end grid-->
                <div v-if="isHome" class="text-center pt-8" style="font-weight:600;font-size: 18px;">
                        <router-link to="/services/serviceTeamComponent"   class="hover:text-blue-600 dark:hover:text-blue-600 after:bg-blue-600 dark:text-white transition duration-500">
                   Learn More about the Compatible Devices <i class="uil uil-arrow-right"></i
                    >
                </router-link>
                </div>

            </div><!--end container-->
        </section><!--end section-->
        <!-- End  -->
    </div>
</template>

<script>
 import feather from 'feather-icons'

    export default {
        name: "featuresVue",
        props:["isHome"],
        data() {
            return {
                servicesData :[
                    {
                        id:1,
                        icon:'uil uil-adjust-circle',
                        title:'Connection Aggregation',
                        desc:"Empowering users to combine multiple network connections into a single, powerful platform to utilize up to 95% of the total combined bandwidth. "
                    },
                    {
                        id:2,
                        icon:'uil uil-database',
                        title:'Hot Failover Technology',
                        desc:'Utilizing MPTCP (Multipath TCP) technology to provide seamless failover and stacked backups when network outages happen. '
                    },
                    {
                        id:3,
                        icon:'uil uil-file-network',
                        title:'Exclusive Network Resource',
                        desc:'Offering a flexible and rapid network configuration capability for accessing local resources remotely while ensuring stable and reliable file transfers. '
                    },
                    
                   
                ]
            }
        },
        components:{

        },
        mounted() {
        feather.replace();
        }
    }
</script>

<style lang="scss" scoped>

</style>