
/*
 image: 图片地址，放在/assets/images文件夹下 ，引用地址require('@/assets/images/blog/1.jpg'),
 title: blog标题
 desc: blog描述，非详情页面显示的描述内容
 blogIndex:按此标识显示对应的详情内容,新增的blog根据之前的+1
 writtenBy: 作者

*/
// 所有blog内容,目前是3个
export const blogData = [
    {
        image: '/images/blog/1.jpg',
        title: "Reliable Livestreaming with AstroWarp: Uninterrupted Internet for Streamers",
        desc: "Discover how AstroWarp helps livestream teams maintain reliable internet connectivity with features like connection aggregation, hot failover, and custom server options. Perfect for seamless streaming.",
        blogIndex: '0',
        writtenBy: "Joyce",
        readTime: '3 Min',
        date: 'August 30,2024'
    },
    {
        image: '/images/blog/2.jpg',
        title: "AstroWarp for Gamers: Experience Lag-Free Gaming Anywhere",
        desc: "Learn how AstroWarp optimizes your gaming experience with connection aggregation, low latency, and seamless failover. Stay connected and enjoy smooth, uninterrupted gameplay.",
        blogIndex: '1',
        writtenBy: "Joyce",
        readTime: '3 Min',
        date: 'August 30,2024'

    },
    {
        image: '/images/blog/4.jpg',
        title: 'AstroWarp for Remote Workers: Secure and Unrestricted Internet Access',
        desc: 'Discover how AstroWarp helps remote workers stay connected securely by accessing the internet through their home network. Bypass VPN restrictions and ensure privacy on the go.',
        blogIndex: '2',
        writtenBy: "Elaine",
        readTime: '3 Min',
        date: 'August 30,2024'
    },

];

// 最近的bolg,目前显示的是3个
export const relatedBlogs = [
    {
        image: '/images/blog/1.jpg',
        title: "Reliable Livestreaming with AstroWarp: Uninterrupted Internet for Streamers",
        desc: "Discover how AstroWarp helps livestream teams maintain reliable internet connectivity with features like connection aggregation, hot failover, and custom server options. Perfect for seamless streaming.",
        blogIndex: '0',
        writtenBy: "Joyce",
        readTime: '3 Min',
        date: 'August 30,2024'
    },
    {
        image: '/images/blog/2.jpg',
        title: "AstroWarp for Gamers: Experience Lag-Free Gaming Anywhere",
        desc: "Learn how AstroWarp optimizes your gaming experience with connection aggregation, low latency, and seamless failover. Stay connected and enjoy smooth, uninterrupted gameplay.",
        blogIndex: '1',
        writtenBy: "Joyce",
        readTime: '3 Min',
        date: 'August 30,2024'

    },
    {
        image: '/images/blog/4.jpg',
        title: 'AstroWarp for Remote Workers: Secure and Unrestricted Internet Access',
        desc: 'Discover how AstroWarp helps remote workers stay connected securely by accessing the internet through their home network. Bypass VPN restrictions and ensure privacy on the go.',
        blogIndex: '2',
        writtenBy: "Elaine",
        readTime: '3 Min',
        date: 'August 30,2024'
    },
    // {
    //     image: '/images/blog/3.jpg',
    //     title: "AstroWarp for Small Businesses: Reliable Internet for Growth and Security",
    //     desc: "Discover how AstroWarp enhances business connectivity with connection aggregation, secure remote access, and seamless failover. Keep your small business running smoothly with AstroWarp.",
    //     blogIndex: '2',
    //     writtenBy: "Joyce",

    // },
];





